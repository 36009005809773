import {booleanAttribute, ChangeDetectionStrategy, Component, Input} from '@angular/core'

@Component({
  selector: 'cft-youtube-video',
  templateUrl: './youtube-video.component.html',
  styles: [':host { display: contents }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubeVideoComponent {
  @Input()
  videoId!: string

  @Input()
  limitRelatedVideosToSameChannel = true

  @Input()
  showControls = true

  @Input({transform: booleanAttribute})
  modestBranding = true

  @Input()
  color: 'white' | 'red' = 'red'

  @Input()
  width: number | string = 560

  @Input()
  height: number | string = 315

  get embedUrl(): string {
    // using the nocookie version for following the gpdr
    return `https://www.youtube-nocookie.com/embed/${this.videoId}?${this.parameters}`
  }

  get parameters() {
    const rel = this.limitRelatedVideosToSameChannel ? 0 : 1
    const controls = this.showControls ? 1 : 0
    const modestBranding = this.modestBranding ? 1 : 0
    return `rel=${rel}&controls=${controls}&modestbranding=${modestBranding}&color=${this.color}&enablejsapi=1`
  }
}
