import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {YoutubeVideoComponent} from './youtube-video.component'
import {PipesModule} from '../../shared/pipes/pipes.module'

@NgModule({
  declarations: [YoutubeVideoComponent],
  imports: [CommonModule, PipesModule],
  exports: [YoutubeVideoComponent],
})
export class YoutubeVideoModule {}
