import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core'
import {Paragraph} from 'datocms-structured-text-utils'
import {DatoRichTextBlock, RichTextInlineItem} from '../../../../../core/content/types/rich-text.types'
import {RichTextLinksContainer} from '../rich-text-dato.component'

/**
 * This component renders paragraphs from DatoCMS's structured text.
 *
 * The following children nodes are unsupported: itemLink
 *
 * https://www.datocms.com/docs/structured-text/dast#paragraph
 */
@Component({
  selector: 'p[dto-rich-text]',
  styles: [
    `
      :host {
        @apply my-md block;
      }

      li :host {
        @apply mt-0;
      }

      :host(.no-padding) {
        @apply my-0;
      }
    `,
  ],
  template: `@for (child of node?.children; track $index) {
    @switch (child.type) {
      @case ('span') {
        <span dto-rich-text [node]="child"></span>
      }
      @case ('link') {
        <a dto-rich-text [node]="child"></a>
      }
      @case ('inlineItem') {
        <div dto-inline-item *ngIf="getInlineItem(child.item); let item" [item]="item"></div>
      }
    }
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoParagraphComponent {
  @Input() node?: Paragraph
  @Input() blocks!: Map<string, DatoRichTextBlock>

  @HostBinding(`class.no-padding`)
  get isInlineItemContainer() {
    return this.node?.children.length === 1 && this.node.children[0].type === 'inlineItem'
  }

  constructor(protected readonly linksContainer: RichTextLinksContainer) {}

  getInlineItem(id: string): RichTextInlineItem | undefined {
    return this.linksContainer.links.get(id)
  }
}
