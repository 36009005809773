@if (videoId) {
  <iframe
    [width]="width"
    [height]="height"
    [src]="embedUrl | safe"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
}
