import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {
  RichBulletPointDato,
  RichButton,
  RichTextInlineItem,
  RichVideo,
} from '../../../../../core/content/types/rich-text.types'
import {PromotionalBannerSection} from '../../../../../core/content/types/promotional-banner-section.types'
import {Accordion} from '../../../../../core/content/types/accordion.types'
import {SignupSection} from '../../../../../core/content/types/signup-section.types'

@Component({
  selector: 'div[dto-inline-item]',
  templateUrl: './inline-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoInlineItemComponent {
  @Input() item!: RichTextInlineItem

  get buttonContent(): RichButton {
    return this.item.entry as RichButton
  }

  get videoContent(): RichVideo {
    return this.item.entry as RichVideo
  }

  get promotionalBannerSection(): PromotionalBannerSection {
    return this.item.entry as PromotionalBannerSection
  }

  get signupSection(): SignupSection {
    return this.item.entry as SignupSection
  }

  get bulletPointContent(): RichBulletPointDato {
    return this.item.entry as RichBulletPointDato
  }

  get accordionContent(): Accordion {
    return this.item.entry as Accordion
  }
}
