import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {StructuredTextPlotFieldsFragment} from '../../../../../../../__generated__/datocms.types'

@Component({
  selector: 'div[dto-rich-text-plot]',
  template: `{{ block.title }}`,
  styles: `
    :host {
      @apply mt-lg plot-md;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoPlotComponent {
  @Input() block!: StructuredTextPlotFieldsFragment
}
