import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core'
import {Span, Mark} from 'datocms-structured-text-utils'

/**
 * This component renders spans from DatoCMS's structured text.
 *
 * Span's are the lowest type of node, containing the actual text.
 * It is responsible for applying those marks (such as bold or underline) to the text.
 *
 * TODO: decide if it's worth it to drop the span in parent nodes when there's only one span without any marks.
 *
 * https://www.datocms.com/docs/structured-text/dast#span
 */
@Component({
  selector: 'span[dto-rich-text]',
  template: `{{ node.value }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoSpanComponent implements OnInit {
  @Input() node!: Span

  @HostBinding('class') get hostClass() {
    const marks = this.node.marks || []
    if (marks.length === 0) {
      return ''
    }
    const classes = marks.map(mapMarks)
    return classes.join(' ')
  }

  ngOnInit() {
    if (this.node.type !== 'span') {
      throw new Error(`Expected node of type "span" but got "${this.node.type}"`)
    }
  }
}

/**
 * Maps DatoCMS's marks to TailwindCSS classes.
 *
 * TODO: unimplemented: code, highlight
 * @param mark
 */
function mapMarks(mark: Mark): string {
  switch (mark) {
    case 'strong':
      return 'font-bold'
    case 'emphasis':
      return 'italic'
    case 'underline':
      return 'underline'
    case 'strikethrough':
      return 'line-through'
    default: {
      console.warn(`Unimplemented mark type: ${mark}`)
      return ''
    }
  }
}
