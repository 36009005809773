<div class="grid items-center" style="grid-template-columns: 2.25rem 1fr">
  <cft-dynamic-icon
    class="inline-flex items-center plot-xl"
    [icon]="bulletPoint.icon"
    [style]="'color: ' + bulletPoint.iconColor"
  ></cft-dynamic-icon>
  <h3 class="inline-flex items-center heading-lg">{{ bulletPoint.lead }}</h3>
  @if (bulletPoint.content; as content) {
    <bgo-rich-text class="col-start-2" [content]="{json: content}" [nested]="true"></bgo-rich-text>
  } @else if (bulletPoint.text) {
    <p class="col-start-2 block text-base">{{ bulletPoint.text }}</p>
  }
</div>
