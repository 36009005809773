import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {AccordionModule} from '../../../craft/accordion/accordion.module'
import {Observable, of} from 'rxjs'
import {FAQPage, Question, WithContext} from 'schema-dts'
import {HasStructuredData} from '../../../core/seo/page-metadata'
import {StructuredDataModule} from '../../../core/seo/structured-data/structured-data.module'
import {AsyncPipe, NgTemplateOutlet} from '@angular/common'

@Component({
  selector: 'bgo-faq-accordion',
  templateUrl: './faq-accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AccordionModule, StructuredDataModule, AsyncPipe, NgTemplateOutlet],
})
export class FaqAccordionComponent implements HasStructuredData<FAQPage> {
  @Input()
  protected readonly questions!: FaqQuestion[]

  getStructuredData$(): Observable<WithContext<FAQPage> | undefined> {
    const questions: Question[] = this.questions.map(({question, answer}) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }))

    return of({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: questions,
    })
  }
}

export interface FaqQuestion {
  question: string
  answer: string
}
