import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {RichBulletPointDato} from '../../../../../../../core/content/types/rich-text.types'

/**
 * Renders inlined bullet point models from datocms.
 *
 * The model has a rich text field for content. That field does not support other
 *
 */
@Component({
  selector: 'bgo-rich-bullet-point',
  templateUrl: './rich-bullet-point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // overwrite normal paragraph padding inside this component
  styles: [
    `
      :host {
        @apply my-md block;
      }
    `,
  ],
})
export class RichBulletPointComponent {
  @Input()
  protected bulletPoint!: RichBulletPointDato
}
