import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core'
import {Heading} from 'datocms-structured-text-utils'

/**
 * This component renders headings from DatoCMS's structured text.
 *
 * The following children nodes are unsupported: link, itemLink and inlineItem
 * ItemLink and initialItem don't really make sense for us.
 * The support of link is debatable, but wasn't used in the past according to our editors
 *
 * https://www.datocms.com/docs/structured-text/dast#heading
 */
@Component({
  selector:
    'h1[dto-rich-text], h2[dto-rich-text], h3[dto-rich-text], h4[dto-rich-text], h5[dto-rich-text], h6[dto-rich-text]',
  template: `
    @for (child of node.children; track $index) {
      @switch (child.type) {
        @case ('span') {
          <span dto-rich-text [node]="child"></span>
        }
      }
    }
  `,
  // when preceded by a plot, margin is handled by the plot component
  styles: `
    *[dto-rich-text-plot] + :host {
      margin-top: 0;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoHeadingComponent implements OnInit {
  @Input() node!: Heading

  @HostBinding('class') get hostClass() {
    return this.headersClasses.get(this.node.level)
  }

  private headersClasses = new Map<number, string>([
    [1, 'heading-2xl mt-lg mb-sm'],
    [2, 'heading-xl mt-lg mb-sm'],
    [3, 'heading-lg mt-lg mb-xs'],
    [4, 'heading-md mt-lg mb-xs'],
    [5, 'heading-sm mt-lg mb-xs'],
    [6, 'heading-xs mt-lg mb-2xs'],
  ])

  ngOnInit() {
    if (this.node.type !== 'heading') {
      throw new Error(`Expected node of type "heading" but got "${this.node.type}"`)
    }
  }
}
