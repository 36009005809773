import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {Accordion, AccordionElement} from '../../../../../../../core/content/types/accordion.types'
import {FaqQuestion} from '../../../../../faq-accordion/faq-accordion.component'
import {render} from 'datocms-structured-text-to-plain-text'

@Component({
  selector: 'bgo-rich-accordion',
  templateUrl: './rich-accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply my-md block;
      }
    `,
  ],
})
export class RichAccordionComponent {
  questions: FaqQuestion[] = []
  elements: AccordionElement[] = []

  @Input() set accordion(value: Accordion) {
    this.elements = value.elements
    this.questions = value.elements.map(({title, content}) => ({
      question: title,
      answer: content.json?.schema === 'dast' ? render(content.json) ?? '' : '',
    }))
  }
}
