@switch (item.type) {
  @case ('componentBulletPoint') {
    <bgo-rich-bullet-point [bulletPoint]="bulletPointContent"></bgo-rich-bullet-point>
  }
  @case ('fieldPromotionBanner') {
    <bgo-promotional-banner-section class="my-md block" [section]="promotionalBannerSection" [fullContent]="true">
    </bgo-promotional-banner-section>
  }
  @case ('fieldSignupSection') {
    <bgo-lead-signup-banner class="my-md block" [section]="signupSection"></bgo-lead-signup-banner>
  }
  @case ('componentButton') {
    <bgo-rich-button [button]="buttonContent"></bgo-rich-button>
  }
  @case ('video') {
    <bgo-rich-video [video]="videoContent"></bgo-rich-video>
  }
  @case ('componentAccordion') {
    <bgo-rich-accordion [accordion]="accordionContent"></bgo-rich-accordion>
  }
}
