@if (block.file) {
  <img
    [ngSrc]="block.file.url"
    [alt]="block.file.title || ''"
    [width]="width"
    [height]="height"
    sizes="(max-width: 1023px) 100vw, calc(100vw * (7/12))"
    class="w-full rounded-3"
  />
}
