<div class="relative h-0 w-full" style="padding-bottom: 56.25%">
  <cft-youtube-video
    class="absolute left-0 top-0 h-full w-full"
    style="display: block"
    width="100%"
    height="100%"
    [videoId]="video.youtubeId"
  >
  </cft-youtube-video>
</div>
