import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core'
import {ListItem} from 'datocms-structured-text-utils'

/**
 * This component renders list items from DatoCMS's structured text.
 *
 * https://www.datocms.com/docs/structured-text/dast#listItem
 */
@Component({
  selector: 'li[dto-rich-text]',
  template: `
    @for (child of node.children; track $index) {
      @switch (child.type) {
        @case ('paragraph') {
          <p dto-rich-text [node]="child"></p>
        }
        @case ('list') {
          @switch (child.style) {
            @case ('bulleted') {
              <ul dto-rich-text [node]="child"></ul>
            }
            @case ('numbered') {
              <ol dto-rich-text [node]="child"></ol>
            }
          }
        }
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoRichListItemComponent implements OnInit {
  @Input() node!: ListItem

  ngOnInit() {
    if (this.node.type !== 'listItem') {
      throw new Error(`Expected node of type "listItem" but got "${this.node.type}"`)
    }
  }
}
