var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
import { defaultMetaTransformer, render as genericHtmlRender, renderNodeRule, renderMarkRule } from 'datocms-structured-text-generic-html-renderer';
import { isBlock, isInlineItem, isItemLink, isStructuredText, RenderError } from 'datocms-structured-text-utils';
export { renderNodeRule, renderMarkRule, RenderError };
// deprecated export
export { renderNodeRule as renderRule };
var renderFragment = function (children) {
  if (!children) {
    return '';
  }
  var sanitizedChildren = children.reduce(function (acc, child) {
    return Array.isArray(child) ? __spreadArrays(acc, child) : __spreadArrays(acc, [child]);
  }, []).filter(function (x) {
    return !!x;
  }).map(function (x) {
    return x.trim();
  });
  if (!sanitizedChildren || sanitizedChildren.length === 0) {
    return '';
  }
  return sanitizedChildren.join(' ');
};
export var defaultAdapter = {
  renderNode: function (tagName, attrs) {
    var children = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      children[_i - 2] = arguments[_i];
    }
    return renderFragment(children);
  },
  renderFragment: renderFragment,
  renderText: function (text) {
    return text;
  }
};
export function render( /** The actual field value you get from DatoCMS **/
structuredTextOrNode, /** Additional render settings **/
settings) {
  var renderInlineRecord = settings === null || settings === void 0 ? void 0 : settings.renderInlineRecord;
  var renderLinkToRecord = settings === null || settings === void 0 ? void 0 : settings.renderLinkToRecord;
  var renderBlock = settings === null || settings === void 0 ? void 0 : settings.renderBlock;
  var customRules = (settings === null || settings === void 0 ? void 0 : settings.customNodeRules) || (settings === null || settings === void 0 ? void 0 : settings.customRules) || [];
  var renderFragment = (settings === null || settings === void 0 ? void 0 : settings.renderFragment) || defaultAdapter.renderFragment;
  var renderText = (settings === null || settings === void 0 ? void 0 : settings.renderText) || defaultAdapter.renderText;
  var renderNode = (settings === null || settings === void 0 ? void 0 : settings.renderNode) || defaultAdapter.renderNode;
  var result = genericHtmlRender(structuredTextOrNode, {
    adapter: {
      renderText: renderText,
      renderNode: renderNode,
      renderFragment: renderFragment
    },
    metaTransformer: settings === null || settings === void 0 ? void 0 : settings.metaTransformer,
    customMarkRules: settings === null || settings === void 0 ? void 0 : settings.customMarkRules,
    customNodeRules: __spreadArrays(customRules, [renderNodeRule(isInlineItem, function (_a) {
      var node = _a.node,
        adapter = _a.adapter;
      if (!renderInlineRecord || !isStructuredText(structuredTextOrNode) || !structuredTextOrNode.links) {
        return null;
      }
      var item = structuredTextOrNode.links.find(function (item) {
        return item.id === node.item;
      });
      if (!item) {
        throw new RenderError("The Structured Text document contains an 'inlineItem' node, but cannot find a record with ID " + node.item + " inside .links!", node);
      }
      return renderInlineRecord({
        record: item,
        adapter: adapter
      });
    }), renderNodeRule(isItemLink, function (_a) {
      var node = _a.node,
        adapter = _a.adapter,
        children = _a.children;
      if (!renderLinkToRecord || !isStructuredText(structuredTextOrNode) || !structuredTextOrNode.links) {
        return renderFragment(children);
      }
      var item = structuredTextOrNode.links.find(function (item) {
        return item.id === node.item;
      });
      if (!item) {
        throw new RenderError("The Structured Text document contains an 'itemLink' node, but cannot find a record with ID " + node.item + " inside .links!", node);
      }
      return renderLinkToRecord({
        record: item,
        adapter: adapter,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: children,
        transformedMeta: node.meta ? ((settings === null || settings === void 0 ? void 0 : settings.metaTransformer) || defaultMetaTransformer)({
          node: node,
          meta: node.meta
        }) : null
      });
    }), renderNodeRule(isBlock, function (_a) {
      var node = _a.node,
        adapter = _a.adapter;
      if (!renderBlock || !isStructuredText(structuredTextOrNode) || !structuredTextOrNode.blocks) {
        return null;
      }
      var item = structuredTextOrNode.blocks.find(function (item) {
        return item.id === node.item;
      });
      if (!item) {
        throw new RenderError("The Structured Text document contains a 'block' node, but cannot find a record with ID " + node.item + " inside .blocks!", node);
      }
      return renderBlock({
        record: item,
        adapter: adapter
      });
    })])
  });
  return result || null;
}
