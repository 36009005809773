import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core'
import {Link} from 'datocms-structured-text-utils'

/**
 * This component renders links from DatoCMS's structured text.
 *
 * A Link may contain marks, this is currently not supported.
 * Based on my testing, marks are contained within the children of the link.
 *
 * https://www.datocms.com/docs/structured-text/dast#link
 */
@Component({
  selector: 'a[dto-rich-text]',
  template: `@for (child of node?.children; track $index) {
    @switch (child.type) {
      @case ('span') {
        <span dto-rich-text [node]="child"></span>
      }
    }
  }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoRichHyperLinkComponent {
  @Input() node?: Link

  private readonly defaultRel = 'noopener noreferrer'
  private readonly defaultTarget = '_blank'

  @HostBinding('class') private readonly class = 'underline'
  @HostBinding('href') get href() {
    return this.node?.url || ''
  }

  @HostBinding('rel') get rel() {
    const meta = this.node?.meta ?? []
    const rel = meta.find(meta => meta.id === 'rel')
    return rel?.value || this.defaultRel
  }

  @HostBinding('target') get target() {
    const meta = this.node?.meta ?? []
    const rel = meta.find(meta => meta.id === 'target')
    return rel?.value || this.defaultTarget
  }
}
