@if (node) {
  <div class="inner-rich-text">
    @for (child of node.children; track $index) {
      @switch (child.type) {
        @case ('paragraph') {
          <p dto-rich-text [node]="child"></p>
        }
        @case ('heading') {
          @switch (child.level) {
            @case (1) {
              <h2 dto-rich-text [node]="child"></h2>
            }
            @case (2) {
              <h3 dto-rich-text [node]="child"></h3>
            }
            @case (3) {
              <h4 dto-rich-text [node]="child"></h4>
            }
            @case (4) {
              <h5 dto-rich-text [node]="child"></h5>
            }
            @case (5) {
              <h6 dto-rich-text [node]="child"></h6>
            }
            @case (6) {
              <h6 dto-rich-text [node]="child"></h6>
            }
          }
        }
        @case ('list') {
          @switch (child.style) {
            @case ('bulleted') {
              <ul dto-rich-text [node]="child"></ul>
            }
            @case ('numbered') {
              <ol dto-rich-text [node]="child"></ol>
            }
          }
        }
        @case ('block') {
          @if (child.type === 'block' && getBlock(child.item || ''); as block) {
            @switch (block.__typename) {
              @case ('StructuredTextAssetRecord') {
                <figure dto-rich-text [block]="block"></figure>
              }
              @case ('TableRecord') {
                <bgo-dato-table [tableData]="block"></bgo-dato-table>
              }
              @case ('StructuredTextPlotRecord') {
                <div dto-rich-text-plot [block]="block" [id]="block.id"></div>
              }
            }
          }
        }
      }
    }
  </div>
}
