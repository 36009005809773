import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {RichVideo} from '../../../../../../../core/content/types/rich-text.types'

@Component({
  selector: 'bgo-rich-video',
  templateUrl: './rich-video.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply my-md block;
      }
    `,
  ],
})
export class RichVideoComponent {
  @Input()
  protected video!: RichVideo
}
