import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core'
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'
import {StructuredData} from '../page-metadata'

@Component({
  selector: 'bgo-json-ld',
  template: '',
})
export class JsonLdComponent implements OnChanges {
  // no idea why tsc wants null there
  @Input() json?: StructuredData | null
  @HostBinding('innerHTML') jsonLD?: SafeHtml

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    this.jsonLD = this.getSafeHTML(changes.json.currentValue)
  }

  getSafeHTML(value: StructuredData) {
    // TODO(BNX-1729): consider safer alternative for escaping string
    const json = value ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>') : ''
    const html = `<script type="application/ld+json">${json}</script>`
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
}
