var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
  for (var r = Array(s), k = 0, i = 0; i < il; i++) for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) r[k] = a[j];
  return r;
};
import { isBlockquote, isCode, isHeading, isLink, isList, isListItem, isParagraph, isRoot, isSpan, render as genericRender, renderRule, RenderError, isThematicBreak } from 'datocms-structured-text-utils';
export { renderRule as renderNodeRule, RenderError };
export function markToTagName(mark) {
  switch (mark) {
    case 'emphasis':
      return 'em';
    case 'underline':
      return 'u';
    case 'strikethrough':
      return 'del';
    case 'highlight':
      return 'mark';
    default:
      return mark;
  }
}
export function renderSpanValue(_a) {
  var node = _a.node,
    key = _a.key,
    _b = _a.adapter,
    renderNode = _b.renderNode,
    renderText = _b.renderText,
    renderFragment = _b.renderFragment;
  var lines = node.value.split(/\n/);
  if (lines.length === 0) {
    return renderText(node.value, key);
  }
  return renderFragment(lines.slice(1).reduce(function (acc, line, index) {
    return acc.concat([renderNode('br', {
      key: key + "-br-" + index
    }), renderText(line, key + "-line-" + index)]);
  }, [renderText(lines[0], key + "-line-first")]), key);
}
export function renderMarkRule(guard, transform) {
  return {
    appliable: typeof guard === 'string' ? function (mark) {
      return mark === guard;
    } : guard,
    apply: transform
  };
}
export function spanNodeRenderRule(_a) {
  var customMarkRules = _a.customMarkRules;
  return renderRule(isSpan, function (context) {
    var adapter = context.adapter,
      key = context.key,
      node = context.node;
    return (node.marks || []).reduce(function (children, mark) {
      if (!children) {
        return undefined;
      }
      var matchingCustomRule = customMarkRules.find(function (rule) {
        return rule.appliable(mark);
      });
      if (matchingCustomRule) {
        return matchingCustomRule.apply({
          adapter: adapter,
          key: key,
          mark: mark,
          children: children
        });
      }
      return adapter.renderNode(markToTagName(mark), {
        key: key
      }, children);
    }, renderSpanValue(context));
  });
}
export var defaultMetaTransformer = function (_a) {
  var meta = _a.meta;
  var attributes = {};
  meta.forEach(function (entry) {
    if (['target', 'title', 'rel'].includes(entry.id)) {
      attributes[entry.id] = entry.value;
    }
  });
  return attributes;
};
export function render(structuredTextOrNode, options) {
  var metaTransformer = options.metaTransformer || defaultMetaTransformer;
  return genericRender(options.adapter, structuredTextOrNode, __spreadArrays(options.customNodeRules || [], [renderRule(isRoot, function (_a) {
    var renderFragment = _a.adapter.renderFragment,
      key = _a.key,
      children = _a.children;
    return renderFragment(children, key);
  }), renderRule(isParagraph, function (_a) {
    var renderNode = _a.adapter.renderNode,
      key = _a.key,
      children = _a.children;
    return renderNode('p', {
      key: key
    }, children);
  }), renderRule(isList, function (_a) {
    var renderNode = _a.adapter.renderNode,
      node = _a.node,
      key = _a.key,
      children = _a.children;
    return renderNode(node.style === 'bulleted' ? 'ul' : 'ol', {
      key: key
    }, children);
  }), renderRule(isListItem, function (_a) {
    var renderNode = _a.adapter.renderNode,
      key = _a.key,
      children = _a.children;
    return renderNode('li', {
      key: key
    }, children);
  }), renderRule(isBlockquote, function (_a) {
    var renderNode = _a.adapter.renderNode,
      key = _a.key,
      node = _a.node,
      children = _a.children;
    var childrenWithAttribution = node.attribution ? __spreadArrays(children || [], [renderNode("footer", {
      key: 'footer'
    }, node.attribution)]) : children;
    return renderNode('blockquote', {
      key: key
    }, childrenWithAttribution);
  }), renderRule(isCode, function (_a) {
    var _b = _a.adapter,
      renderNode = _b.renderNode,
      renderText = _b.renderText,
      key = _a.key,
      node = _a.node;
    return renderNode('pre', {
      key: key,
      'data-language': node.language
    }, renderNode('code', null, renderText(node.code)));
  }), renderRule(isLink, function (_a) {
    var renderNode = _a.adapter.renderNode,
      key = _a.key,
      children = _a.children,
      node = _a.node;
    var meta = node.meta ? metaTransformer({
      node: node,
      meta: node.meta
    }) : {};
    return renderNode('a', __assign(__assign({}, meta || {}), {
      key: key,
      href: node.url
    }), children);
  }), renderRule(isThematicBreak, function (_a) {
    var renderNode = _a.adapter.renderNode,
      key = _a.key;
    return renderNode('hr', {
      key: key
    });
  }), renderRule(isHeading, function (_a) {
    var node = _a.node,
      renderNode = _a.adapter.renderNode,
      children = _a.children,
      key = _a.key;
    return renderNode("h" + node.level, {
      key: key
    }, children);
  }), spanNodeRenderRule({
    customMarkRules: options.customMarkRules || []
  })]));
}
