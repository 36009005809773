import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core'
import {StructuredTextAssetFieldsFragment} from '../../../../../../../__generated__/datocms.types'

@Component({
  selector: 'figure[dto-rich-text]',
  templateUrl: './dato-img.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply my-sm block;
      }
    `,
  ],
})
export class DatoImgComponent implements OnInit {
  @Input() block!: StructuredTextAssetFieldsFragment

  protected width?: number
  protected height?: number

  ngOnInit(): void {
    const file = this.block.file
    // according to [doc](https://angular.io/guide/image-directive#step-5-include-height-and-width),
    // responsive images should have a width and height set to their intrinsic size
    // oddly, when doing that, no automatic srcset is generated for the image making the browser always load the original version
    // what we do here is calculate the width and height based on the biggest the container can get
    if (file?.width && file?.height) {
      const maxContainerWidth = 821 // 7/12 cols of the grid at max screen width
      const imageAspectRatio = file.width / file.height
      this.width = maxContainerWidth
      this.height = Math.round(this.width / imageAspectRatio) // maintain aspect ratio
    }
  }
}
