import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {List} from 'datocms-structured-text-utils'

@Component({
  selector: 'ol[dto-rich-text]',
  template: `
    @for (child of node?.children; track $index) {
      <li dto-rich-text [node]="child"></li>
    }
  `,
  styles: [':host { @apply ml-xl mt-sm list-decimal; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatoOrderedListComponent {
  @Input() node?: List
}
