import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {RichButton} from '../../../../../../../core/content/types/rich-text.types'
import {Router} from '@angular/router'
import {isAbsoluteUrl} from '../../../../../../../core/utils/url'

@Component({
  selector: 'bgo-rich-button',
  templateUrl: './rich-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply mb-lg mt-md block;
      }
    `,
  ],
})
export class RichButtonComponent {
  @Input()
  protected button!: RichButton

  constructor(private readonly router: Router) {}

  protected isAbsoluteUrl(url: string): boolean {
    return isAbsoluteUrl(url)
  }

  protected richButtonClick(event: MouseEvent, path: string): void {
    if (!this.isAbsoluteUrl(path)) {
      event.preventDefault()
      this.router.navigate([path])
    }
  }
}
