import {ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core'
import {TableRecord} from '../../../../../../../__generated__/datocms.types'

@Component({
  selector: 'bgo-dato-table',
  styleUrl: 'dato-table.component.css',
  templateUrl: './dato-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply my-md block;
      }
    `,
  ],
})
export class DatoTableComponent implements OnInit {
  @Input({required: true}) tableData!: Pick<TableRecord, 'tableContent'>
  columns: string[] = []
  tableContent: Record<string, string>[] = []

  scrollTable = false
  lastPosition = 0

  @ViewChild('container') container!: ElementRef<HTMLDivElement>

  ngOnInit() {
    this.columns = this.tableData.tableContent.columns
    this.tableContent = this.tableData.tableContent.data
  }

  drag(e: MouseEvent) {
    if (e.type === 'mousedown') {
      this.scrollTable = true
      this.lastPosition = e.clientX
      return
    }

    if (e.type === 'mouseup') {
      this.scrollTable = false
      return
    }

    if (e.type === 'mousemove' && this.scrollTable) {
      const table = this.container.nativeElement
      const position = e.clientX
      const difference = position - this.lastPosition
      table.scrollBy(-difference, 0)
      this.lastPosition = e.clientX
    }
  }

  @HostListener('window:mouseup')
  mouseUp() {
    this.scrollTable = false
  }
}
