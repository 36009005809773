import {booleanAttribute, Component, Input} from '@angular/core'
import {PromotionalBannerSection} from '../../../../core/content/types/promotional-banner-section.types'
import {AsyncPipe, NgClass, NgOptimizedImage} from '@angular/common'
import {TranslocoDirective} from '@ngneat/transloco'
import {ButtonModule} from '../../../../craft/button/button.module'

@Component({
  selector: 'bgo-promotional-banner-section',
  templateUrl: './promotional-banner-section.component.html',
  styleUrl: './promotional-banner-section.component.css',
  imports: [AsyncPipe, TranslocoDirective, NgOptimizedImage, NgClass, ButtonModule],
  standalone: true,
})
export class PromotionalBannerSectionComponent {
  @Input() section!: PromotionalBannerSection

  /** Content will be displayed in full width when true, otherwise it will be limited to a chunk of the width */
  @Input({transform: booleanAttribute}) fullContent = false
}
