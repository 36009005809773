import {ChangeDetectionStrategy, Component, HostBinding, Injectable, Input} from '@angular/core'
import {Root} from 'datocms-structured-text-utils'
import {DatoRichTextBlock, DatoRichTextLinks, RichTextDocument} from '../../../../core/content/types/rich-text.types'

@Injectable()
export class RichTextLinksContainer {
  readonly links = new Map<string, DatoRichTextLinks>()

  public addLinks(links: Map<string, DatoRichTextLinks>) {
    for (const [key, value] of links) {
      this.links.set(key, value)
    }
  }
}

/**
 * This component renders rich text from DatoCMS's structured text.
 *
 * The implementation differs quiet a bit from contentful, the `dast` (dato' syntax tree) mimics html much more closely,
 * resulting in simpler rendering.
 *
 * Every Node type is rendered by one component.
 * Rendering of blocks is handled by this component, as they are only allowed as part of the root node.
 *
 * In cf the nesting of node's was much more flexible and arbitrary, making things like marks harder to implement,
 * which lead to the implementation nesting the richtext component.
 * This is not the case anymore!
 * (except for linked models)
 *
 * TODO(BNX-2082): unimplemented blockquote, thematicbreak
 *
 * https://www.datocms.com/docs/structured-text/dast#root
 */
@Component({
  selector: 'bgo-rich-text',
  templateUrl: './rich-text-dato.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RichTextLinksContainer],
  host: {ngSkipHydration: 'true'}, //eslint-disable-line @angular-eslint/no-host-metadata-property
  styles: [
    `
      /* Css for the rich-bullet point */
      :host.nested .inner-rich-text *:first-child {
        margin-top: 0;
        padding-top: 0;
      }

      :host.nested .inner-rich-text *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    `,
  ],
})
export class RichTextDatoComponent {
  @Input() set content(content: RichTextDocument) {
    this.node = content.json?.document
    this.blocks = content.blocks || new Map()
    this.links = content.links || new Map()
    this.linksContainer.addLinks(this.links)
  }
  @Input()
  @HostBinding('class.nested')
  protected nested = false

  protected node?: Root
  private blocks!: Map<string, DatoRichTextBlock>
  private links!: Map<string, DatoRichTextLinks>

  constructor(private readonly linksContainer: RichTextLinksContainer) {}

  /**
   * Returns a block by id, undefined if the block is not found.
   * A block could be not found when its type is not supported by the current implementation.
   * It should then be ignored
   */
  getBlock(id: string): DatoRichTextBlock | undefined {
    return this.blocks.get(id)
  }
}
