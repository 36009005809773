import {NgModule} from '@angular/core'
import {CommonModule, NgOptimizedImage} from '@angular/common'
import {AccordionModule} from '../../../craft/accordion/accordion.module'
import {DynamicIconModule} from '../../../craft/dynamic-icon/dynamic-icon.module'
import {YoutubeVideoModule} from '../../../craft/youtube-video/youtube-video.module'
import {FaqAccordionComponent} from '../faq-accordion/faq-accordion.component'
import {RichTextDatoComponent} from './rich-text-dato/rich-text-dato.component'
import {DatoUnorderedListComponent} from './rich-text-dato/elements/dato-ul.component'
import {DatoOrderedListComponent} from './rich-text-dato/elements/dato-ol.component'
import {DatoRichListItemComponent} from './rich-text-dato/elements/dato-li.component'
import {DatoParagraphComponent} from './rich-text-dato/elements/dato-p.component'
import {DatoSpanComponent} from './rich-text-dato/elements/dato-span.component'
import {DatoHeadingComponent} from './rich-text-dato/elements/dato-h.component'
import {DatoRichHyperLinkComponent} from './rich-text-dato/elements/dato-a.component'
import {DatoImgComponent} from './rich-text-dato/blocks/dato-img/dato-img.component'
import {DatoInlineItemComponent} from './rich-text-dato/inline-item/inline-item.component'
import {RichBulletPointComponent} from './rich-text-dato/inline-item/items/rich-bullet-point/rich-bullet-point.component'
import {RichButtonComponent} from './rich-text-dato/inline-item/items/rich-button/rich-button.component'
import {RichVideoComponent} from './rich-text-dato/inline-item/items/rich-video/rich-video.component'
import {RichAccordionComponent} from './rich-text-dato/inline-item/items/rich-accordion/rich-accordion.component'
import {TableModule} from '../../../craft/table/table.module'
import {DatoTableComponent} from './rich-text-dato/blocks/dato-table/dato-table.component'
import {DatoPlotComponent} from './rich-text-dato/blocks/dato-plot/dato-plot.component'
import {PromotionalBannerSectionComponent} from '../content/promotional-banner-section/promotional-banner-section.component'
import {LeadSignupBannerComponent} from '../content/lead-signup-section/lead-signup-banner.component'

@NgModule({
  declarations: [
    RichTextDatoComponent,
    // Elements
    DatoUnorderedListComponent,
    DatoOrderedListComponent,
    DatoRichListItemComponent,
    DatoParagraphComponent,
    DatoSpanComponent,
    DatoSpanComponent,
    DatoHeadingComponent,
    DatoRichHyperLinkComponent,
    // Blocks
    DatoImgComponent,
    DatoPlotComponent,
    // Inline items
    DatoInlineItemComponent,
    RichBulletPointComponent,
    RichButtonComponent,
    RichVideoComponent,
    RichAccordionComponent,
    DatoTableComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    DynamicIconModule,
    YoutubeVideoModule,
    PromotionalBannerSectionComponent,
    FaqAccordionComponent,
    AccordionModule,
    TableModule,
    LeadSignupBannerComponent,
  ],
  exports: [RichTextDatoComponent, RichAccordionComponent],
})
export class RichTextModule {}
