<div class="inline-block">
  <a
    class="btn btn-elevated"
    [class.btn-primary]="button.type === 'Primary'"
    [class.btn-secondary]="button.type === 'Secondary'"
    [attr.href]="button.targetPath"
    [target]="isAbsoluteUrl(button.targetPath) ? '_blank' : '_self'"
    (click)="richButtonClick($event, button.targetPath)"
  >
    {{ button.label }}
  </a>
</div>
