import {ChangeDetectionStrategy, Component, DestroyRef, input} from '@angular/core'
import {WaveCoverImageModule} from '../../../../craft/wave-cover-image/wave-cover-image.module'
import {NgOptimizedImage} from '@angular/common'
import {SignupSection} from '../../../../core/content/types/signup-section.types'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {FormsModule} from '../../../../craft/forms/forms.module'
import {TranslocoDirective, TranslocoService} from '@ngneat/transloco'
import {ButtonModule} from '../../../../craft/button/button.module'
import {BehaviorSubject, mergeMap} from 'rxjs'
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {SignupSection_CreateLeadGql} from '../../../../../__generated__/types'
import {FullNamePipe} from '../../../pipes/full-name.pipe'
import {Router} from '@angular/router'
import {ToastrService} from 'ngx-toastr'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop'

@Component({
  selector: 'bgo-lead-signup-banner',
  standalone: true,
  imports: [
    WaveCoverImageModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    FormsModule,
    TranslocoDirective,
    ButtonModule,
    FaIconComponent,
  ],
  providers: [FullNamePipe],
  templateUrl: './lead-signup-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply relative block min-h-56;
      }
    `,
  ],
})
export class LeadSignupBannerComponent {
  section = input.required<SignupSection>()

  readonly controls = {
    firstName: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    lastName: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
  }

  readonly form = new FormGroup(this.controls)

  private readonly loading = new BehaviorSubject<boolean>(false)

  constructor(
    private readonly createLeadGql: SignupSection_CreateLeadGql,
    private readonly fullNamePipe: FullNamePipe,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef,
    private readonly toastrService: ToastrService,
    private readonly translocoService: TranslocoService,
  ) {}

  get isLoading$() {
    return this.loading.asObservable()
  }

  submitLead() {
    this.form.markAllAsTouched()
    if (this.form.invalid) {
      return
    }

    this.loading.next(true)

    const fields = this.form.getRawValue()

    this.createLeadGql
      .mutate({
        req: {
          firstAndLastName: this.fullNamePipe.transform({firstName: fields.firstName, lastName: fields.lastName}),
          email: fields.email,
          referrer: this.router.url,
          message: 'N/A',
          topic: this.section().topic,
        },
      })
      .pipe(
        mergeMap(() => this.translocoService.selectTranslate<string>('signupSection.successMessage')),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((message: string) => {
        this.loading.next(false)
        this.form.reset()
        this.toastrService.success(message)
      })
  }

  protected readonly lockIcon = faLock
}
